import * as React from 'react'
import Container from '@mui/material/Container';

type Props = {
    backgroundColor?: string,
    children?: React.ReactNode
}

const PageContainer  = ({backgroundColor, children}: Props) => {


  return (
    <Container maxWidth={false} className="cf-maincontainer" sx={{ backgroundColor: backgroundColor }} >
      {children}
    </Container>
  )
}
export default PageContainer