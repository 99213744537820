import React, { useState } from "react";
import Layout from '../components/layout'
import './app.scss';
import home_service from '../images/home_service.jpg'
import home_enginerebuilds from '../images/home_enginerebuilds.jpg'
import home_carboncleaning from '../images/home_carboncleaning.png'

import configData from "../config.json";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Link, Stack, styled } from "@mui/material/";
import axios from "axios";


const Home = () => {
    const [busyIndicator, setBusyIndicator] = React.useState(false);
    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [models, setModels] = React.useState([]);
    const [modelsLoaded, setModelsLoaded] = React.useState(false);
    const [items, setItems] = useState([]);


    const [category, setCategory] = React.useState('Women');


    const handleCategory = (
        event: React.MouseEvent<HTMLElement>,
        newCategory: string,
    ) => {
        setCategory(newCategory);
        setModelsLoaded(false);
    };

    const [bookingOption, setBookingOption] = useState("booking");
    let navigate = useNavigate();

    const handleDetailItem = (item: any) => {
        navigate("/detail?id=" + item.id);
    };

    const handleWorkForUs = () => {
        navigate("/workforus")
    };


    const handleLogin = () => {
        navigate("/login")
    };

    const GoldenToggleButton = styled(ToggleButton)({
        '&,&:hover': {
            backgroundColor: '#826119',
            color: "#231a1a"
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: '#d3a744',
            color: "#231a1a"
        }
    });



    React.useEffect(() => {
        setBusyIndicator(true);
        var query = axios.get(
            configData.API_URL + "index.php/model/publicFindByCategory?category=" + category + "&XDEBUG_SESSION_START=PHPSTORM", {}
        );
        query.then(function (responses: any) {
            setBusyIndicator(false);
            setModelsLoaded(true);
            setItems(responses.data);
        })
            .catch(function (error: any) {
                setErrorMessage("We were unable to complete the catgories query");
                setErrorMessageOpen(true);
                setBusyIndicator(false);
                console.log(error);
            });

    }, [modelsLoaded]);


    return (
        <div>

            <Box className="contentHolder" sx={{ marginTop: "0.2rem" }}>
                <Box sx={{ paddingLeft: "1rem" }} className="headlineWithLine">
                    <img className="headerImage" src={require("../images/adultentainmentagency.png")} style={{ height: "2.2rem" }} ></img>

                </Box>
                <Box>

                    <p style={{ color: "rgb(211, 167, 68)" }}>We are not just escorts</p>

                    <p style={{ color: "rgb(211, 167, 68)" }}>We are different</p>



                    <p style={{ color: "white" }}>We can provide escorts, duos groups, live sex shows, webcam, as well as full movie production.</p>

                    <p style={{ color: "white" }}>We can provide for any event or function any celebration any fantasy any movie scene, or Dream. </p>
                </Box>
                <Box sx={{ paddingLeft: "1rem" }} className="headlineWithLine">

                    <img className="headerImage" src={require("../images/priceonrequest.png")} style={{ height: "2.2rem" }} ></img>
                </Box>
                <Box>

                    <p style={{ color: "white" }}>With Dreams Desire Creation  anything is possible</p>

                    <p style={{ color: "white" }}>In fact, if it's plausible</p>
                    <p style={{ color: "white" }}>it's possible</p>
                    <p style={{ color: "white" }}>if it's possible it's already happened</p>

                    <p style={{ color: "white" }}>So enjoy the reality we provide </p>
                </Box>
            </Box>

            <Box className="contentHolder" sx={{ marginTop: "0.2rem" }}>
                <Box sx={{ paddingLeft: "1rem" }} className="headlineWithLine">

                    <img className="headerImage" src={require("../images/latestescorts.png")} style={{ height: "2.4rem" }} ></img>
                </Box>
            </Box>

            <Stack justifyContent="center" alignItems="center">
                <ToggleButtonGroup sx={{ marginTop: "0.6rem", marginLeft: "1.6rem" }}
                    color="primary"
                    exclusive
                    value={category}
                    onChange={handleCategory}
                >
                    <GoldenToggleButton sx={{ width: "10rem" }} value="Women">Women</GoldenToggleButton>
                    <GoldenToggleButton sx={{ width: "10rem" }} value="Men">Men</GoldenToggleButton>
                    <GoldenToggleButton sx={{ width: "10rem" }} value="Trans">Trans</GoldenToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Grid container spacing={4} sx={{
                paddingLeft: { xs: "0.6rem", md: "6.4rem" },
                paddingRight: { xs: "0.6rem", md: "2.4rem" },
                paddingTop: "1rem",
                paddingBottom: "6.4rem"
            }}>
                {items.map((item: any, index: number) => (
                    <Grid item xs={12} md={3}>
                        <Card onClick={(e) => handleDetailItem(item)}>
                            <CardMedia
                                sx={{ height: "34rem" }}
                                image={configData.API_URL + item.mainImage}
                            />
                            <CardContent sx={{ backgroundColor: "#171717", backgroundOrigin: "center 0" }}>
                                <Typography gutterBottom variant="h5" component="div" sx={{ color: "#d3a744", fontStyle: "italic" }}>
                                    {item.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ color: "white" }}>
                                    {item.description}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{ backgroundColor: "#171717" }}>
                                <Button size="small" sx={{ color: "#FED819" }} >View more</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* <Carousel className="mainCarousel" itemsPerPageS="1" itemsPerPageM="2" itemsPerPageL="3">

      <div className="carousel-cell teaserOffers02CMS contain is-selected">
                    <div className="imageHolderCMS contain">
                        <img alt="" src={home_service} />
                    </div>
               
                    <div className="richText arrowLink-ch">
                        <h3 className="smallHeader"></h3>
                        <h4>Service</h4>
                        <p>We provide a range of affordable car service packages to suit your needs. Vehicle servicing is carried out by our highly trained service technicians and we use parts that match the quality of the manufacturer's original equipment. </p>
                        <a href="/servicing">Learn more</a>
                    </div>
                </div>

                <div className="carousel-cell teaserOffers02CMS contain" aria-hidden="true">
                    <div className="imageHolderCMS contain">
                        <img alt="" src={require("../images/mechanicalRepairs_welding.jpg")} />
                    </div>
               
                    <div className="richText arrowLink-ch">
                        <h3 className="smallHeader"></h3>
                        <h4>Mechanical Repairs</h4>
                        <p>Jiffylube offer a variety of repair and mechanical work, with almost all of our services carried out by our team of experienced technicians and mechanics in our specially designed workshop.</p>
                        <a href="/mechanicalRepairs">Learn more</a>
                    </div>
                </div>

                <div className="carousel-cell teaserOffers02CMS contain" aria-hidden="true">
                    <div className="imageHolderCMS contain">
                        <img alt="" src={home_carboncleaning} />
                    </div>
               
                    <div className="richText arrowLink-ch">
                        <h3 className="smallHeader"></h3>
                        <h4>Engine carbon cleaning</h4>
                        <p>Over time deposits build up in the engine and fuel system, dramatically reducing the combustion efficiency of the engine. Our Engine carbon cleaning service will improve MPG, performance, and reduce emissions. By making your vehicle's engine more efficient you will ultimately save money and travel further.</p>
                        <a href="/carboncleaning">Learn more</a>
                    </div>
                </div>

                <div className="carousel-cell teaserOffers02CMS contain" aria-hidden="true">
                    <div className="imageHolderCMS contain">
                        <img alt="" src={require("../images/maintenance_brakediscsandpads.jpg")} />
                    </div>
               
                    <div className="richText arrowLink-ch">
                        <h3 className="smallHeader"></h3>
                        <h4>Maintenance</h4>
                        <p>Jiffylube offer a variety of repair and mechanical work, with almost all of our services carried out by our team of experienced technicians and mechanics in our specially designed workshop.</p>
                        <a href="/maintenance">Learn more</a>
                    </div>
                </div> 
        </Carousel> */}


            <Stack justifyContent="center" alignItems="center" sx={{ marginBottom: "2rem" }}>
                <Button size="small" onClick={handleWorkForUs} variant="contained" sx={{ width: "10rem", backgroundColor: "#a68741", fontWeight: 700, textTransform: "none", fontSize: "1.2rem" }}>Work with us</Button>
            </Stack>


            <Stack justifyContent="center" alignItems="center" sx={{ marginBottom: "2rem" }}>

                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        navigate("/login");
                    }}
                >
                    Login
                </Link>

            </Stack>

        </div>

    )
}
export default Home