import { useState } from "react";
import '../app.scss';

import DraggableList from "../../components/DraggableList";
import { Item } from "../../types/Item";
import { DropResult, ResponderProvided } from "@hello-pangea/dnd";
import React from "react";
import configData from "../../config.json";
import axios from "axios";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Link, MenuItem, OutlinedInput, Select, Snackbar, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Webcam from "@uppy/webcam";
import XHR from '@uppy/xhr-upload';
import Uppy, { Meta, UppyFile } from "@uppy/core";
import { Dashboard, useUppyEvent } from "@uppy/react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DraggableListImage from "../../components/DraggableListImage";
import { ModelImage } from "../../types/ModelImage";
import { getAuth } from "firebase/auth";

var baseItems: ModelImage[] = [];

const CallbackPage = () => {

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();



  const [idToken, setIdToken] = React.useState("");


  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [name, setName] = React.useState<string>("");
  const [modelName, setModelName] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [requestedDateTime, setRequestedDateTime] = React.useState<string>("");
  const [duration, setDuration] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [comments, setComments] = React.useState<string>("");

  const handleErrorMessageClose = (oEvent: any) => {
    setErrorMessageOpen(false)
  }

  const handleClose = () => {

    navigate("/admin/prospects");

  };

  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
        (window as any).idToken = idToken;
        var query = axios.get(
          configData.API_URL + "index.php/callback/get?id=" + id, {
          headers: {
            Authorization: idToken
          },
        }
        );

        query.then(function (responses: any) {
          setBusyIndicator(false);
          setLoaded(true);
          setName(responses.data.name);
          setModelName(responses.data.modelName);
          setPhone(responses.data.phone);
          setEmail(responses.data.email);
          setRequestedDateTime(responses.data.requestedDateTime);
          setDuration(responses.data.duration);
          setLocation(responses.data.location);
          setComments(responses.data.comment);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the callbacks query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
    else {
      setLoaded(true);
    }


  }, [loaded]);


  const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: "0.25rem"
  }));

  return (
    <Stack sx={{ marginLeft: "1rem", marginRight: "1rem" }}>

      <Stack direction="row" spacing={1} sx={{ marginTop: "1rem" }}>
        <Typography gutterBottom sx={{ flex: "1 1" }} variant="h5">Model: {name}</Typography>
        <Button variant="outlined" onClick={handleClose}>Close</Button>
      </Stack>

      <Grid container spacing={3} sx={{ paddingLeft: "1rem", marginTop: "0.5rem" }}>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Name
          </FormLabel>
          <Typography>{name}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            For
          </FormLabel>
          <Typography>{modelName}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Phone
          </FormLabel>
          <Link href={"tel:" + phone} variant="body2">
            {phone}
          </Link>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Email
          </FormLabel>
          <Typography>{email}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
          Requested Date/Time
          </FormLabel>
          <Typography>{requestedDateTime}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
          Duration
          </FormLabel>
          <Typography>{duration}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
          Location
          </FormLabel>
          <Typography>{location}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
          Comments
          </FormLabel>
          <Typography>{comments}</Typography>
        </FormGrid>


      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessageOpen}
        onClose={handleErrorMessageClose}
        message={errorMessage}

      />

    </Stack>

  )
}
export default CallbackPage