import React, { FC } from 'react';
import DraggableListImageItem from './DraggableListImageItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import { List } from '@mui/material';
import { ModelImage } from '../types/ModelImage';

export type Props = {
  items: ModelImage[];
  onDragEnd: OnDragEndResponder;
  onDelete: (e: any) => void;
  mainPath: string;
  onSelectMain: (path: string) => void;
};


const DraggableListImage: FC<Props> = React.memo(({ items, onDragEnd, onDelete, mainPath, onSelectMain }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item: ModelImage, index: number) => (
              <DraggableListImageItem item={item} index={index} key={item.id} mainPath={mainPath} onDelete={onDelete} onSelectMain={onSelectMain} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableListImage;
