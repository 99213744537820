import { useState } from "react";
import '../app.scss';

import DraggableList from "../../components/DraggableList";
import { Item } from "../../types/Item";
import { DropResult, ResponderProvided } from "@hello-pangea/dnd";
import React from "react";
import configData from "../../config.json";
import axios from "axios";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Grid2, MenuItem, OutlinedInput, Select, Snackbar, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Webcam from "@uppy/webcam";
import XHR from '@uppy/xhr-upload';
import Uppy, { Meta, UppyFile } from "@uppy/core";
import { Dashboard, useUppyEvent } from "@uppy/react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DraggableListImage from "../../components/DraggableListImage";
import { ModelImage } from "../../types/ModelImage";
import { getAuth } from "firebase/auth";

var baseItems: ModelImage[] = [];

const ModelPage = () => {

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();



  const [idToken, setIdToken] = React.useState("");

  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [age, setAge] = React.useState<string>("");
  const [nationality, setNationality] = React.useState<string>("");
  const [stats, setStats] = React.useState<string>("");
  const [height, setHeight] = React.useState<string>("");
  const [languages, setLanguages] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [mainImage, setMainImage] = React.useState<string>("");
  const [active, setActive] = React.useState<boolean>(false);
  const [images, setImages] = useState(baseItems);
  const [uppy] = useState(() => new Uppy({}).use(Webcam).use(XHR, {
    endpoint: configData.API_URL + "index.php/modelImage/upload?model=" + searchParams.get("id") + "&XDEBUG_SESSION_START=PHPSTORM", onBeforeRequest: function (xhr: any) {
      xhr.setRequestHeader('Authorization', (window as any).idToken);
    }
  }));

  const handleErrorMessageClose = (oEvent: any) => {
    setErrorMessageOpen(false)
  }

  const handleSave = () => {

    var user = getAuth().currentUser;
    user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {

      var path = "index.php/model/add";

      var id = searchParams.get("id");
      if (id) {
        path = "index.php/model/update?id=" + id + "&XDEBUG_SESSION_START=PHPSTORM";
      }

      var query = axios.post(
        configData.API_URL + path, {
        Name: name,
        Description: description,
        Age: age,
        Nationality: nationality,
        Stats: stats,
        Height: height,
        Languages: languages,
        Location: location,
        Category: category,
        MainImage: mainImage,
        Active: active
      }, {
        headers: {
          Authorization: idToken
        },
      }
      );

      query.then(function (responses: any) {
        setBusyIndicator(false);
        navigate("/admin/models");
      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the save query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });
    })
  };

  const handleCancel = () => {

    navigate("/admin/models");

  };

  const handleUploadComplete = (e: any) => {
    setImagesLoaded(false);
    uppy.clear();
  };

  const handleMainPath = (mainPath: string) => {
    setMainImage(mainPath);

  };

  useUppyEvent(uppy, 'complete', handleUploadComplete)


  const onImageDelete = (item: any) => {
    var user = getAuth().currentUser;
    user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
      var query = axios.post(
        configData.API_URL + "index.php/modelImage/delete?id=" + item.id, {
      },
        {
          headers: {
            Authorization: idToken
          }
        }
      );

      query.then(function (responses: any) {
        setBusyIndicator(false);
        setImagesLoaded(false);

      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the image delete query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });
    })
  }


  const onImageDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const result = Array.from(images);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    setImages(result);

    var sourceItem = images[source.index];

    var user = getAuth().currentUser;
    user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {

      var query = axios.post(
        configData.API_URL + "index.php/modelImage/reorder?id=" + sourceItem.id + "&order=" + destination.index + "&XDEBUG_SESSION_START=PHPSTORM", {}, {
        headers: {
          Authorization: idToken
        },
      }
      );

      query.then(function (responses: any) {
        setBusyIndicator(false);
        setImagesLoaded(false);

      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the reorder query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });
    })
  };



  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
        (window as any).idToken = idToken;
        var query = axios.get(
          configData.API_URL + "index.php/model/get?id=" + id, {
          headers: {
            Authorization: idToken
          },
        }
        );

        query.then(function (responses: any) {
          setBusyIndicator(false);
          setLoaded(true);
          setName(responses.data.name);
          setDescription(responses.data.description);
          setAge(responses.data.age);
          setNationality(responses.data.nationality);
          setStats(responses.data.stats);
          setHeight(responses.data.height);
          setLanguages(responses.data.languages);
          setLocation(responses.data.location);
          setCategory(responses.data.category);
          setMainImage(responses.data.mainImage)
          setActive(responses.data.active);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the models query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
    else {
      setLoaded(true);
    }


  }, [loaded]);


  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {

        var query2 = axios.get(
          configData.API_URL + "index.php/modelImage/findByModel?model=" + id + "&XDEBUG_SESSION_START=PHPSTORM", {
          headers: {
            Authorization: idToken
          },
        }
        );

        query2.then(function (responses: any) {
          setBusyIndicator(false);
          var items = []
          for (let i = 0; i < responses.data.length; i++) {
            const element = responses.data[i];
            items.push({ id: "" + element.id, path: element.path, description: element.description, orderIndex: element.orderIndex })
          }
          setImages(items);
          setImagesLoaded(true);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the model images query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
    else {
      setLoaded(true);
    }


  }, [imagesLoaded]);

  const FormGrid = styled(Grid2)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: "0.25rem"
  }));

  return (
    <Stack sx={{ marginLeft: "1rem", marginRight: "1rem" }}>

      <Stack direction="row" spacing={1} sx={{ marginTop: "1rem" }}>
        <Typography gutterBottom sx={{ flex: "1 1" }} variant="h5">Escort: {name}</Typography>
        <Button variant="contained" onClick={handleSave}>Save</Button>
        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
      </Stack>

      <Grid2 container spacing={3} sx={{ paddingLeft: "1rem", marginTop: "0.5rem" }}>
        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
          <FormLabel>
            Name
          </FormLabel>
          <TextField label="Name" variant="filled" value={name} onChange={(e) => setName(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
          <FormLabel>
            Category
          </FormLabel>
          <Select
            label="Category"
            variant="filled"
            value={category}
            onChange={(e) => setCategory(e.target.value)}  >
            <MenuItem value="Women">Women</MenuItem>
            <MenuItem value="Men">Men</MenuItem>
            <MenuItem value="Trans">Trans</MenuItem>
          </Select>
        </Grid2>

        <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel htmlFor="zip">
            Description
          </FormLabel>
          <TextField label="Description" multiline rows={4} variant="filled" value={description} onChange={(e) => setDescription(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Age
          </FormLabel>
          <TextField label="Age" variant="filled" value={age} onChange={(e) => setAge(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
          <FormLabel>
            Languages
          </FormLabel>
          <TextField label="Languages" variant="filled" value={languages} onChange={(e) => setLanguages(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Nationality
          </FormLabel>
          <TextField label="Nationality" variant="filled" value={nationality} onChange={(e) => setNationality(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Location
          </FormLabel>
          <TextField label="Location" variant="filled" value={location} onChange={(e) => setLocation(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Stats
          </FormLabel>
          <TextField label="Stats" variant="filled" value={stats} onChange={(e) => setStats(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Height
          </FormLabel>
          <TextField label="Height" variant="filled" value={height} onChange={(e) => setHeight(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Languages
          </FormLabel>
          <TextField label="Languages" variant="filled" value={languages} onChange={(e) => setLanguages(e.target.value)} />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', flexDirection: 'column', padding: "0.25rem" }}>
        <FormLabel>
            Active
          </FormLabel>
          <FormGroup>
            <FormControlLabel control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />} label="Active" />
          </FormGroup>
        </Grid2>

      </Grid2>

      <Stack spacing={1}>


        {searchParams.get("id") != null && searchParams.get("id") != "0" ? <Box>
          <DraggableListImage items={images} mainPath={mainImage} onSelectMain={handleMainPath} onDragEnd={onImageDragEnd} onDelete={onImageDelete} />
          <Dashboard uppy={uppy} height={200} />

        </Box> : ""}



      </Stack>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessageOpen}
        onClose={handleErrorMessageClose}
        message={errorMessage}

      />

    </Stack>

  )
}
export default ModelPage