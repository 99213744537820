import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layout'
import Home from "./pages/home";
import Detail from "./pages/detail";


import LoginPage from "./pages/login";


import { ProtectedRoute } from "./components/protectedroute";
import { AuthProvider } from "./hooks/useAuth";
import WorkForUs from './pages/workforus';
import AdminLayout from './components/adminlayout';
import ModelsPage from './pages/admin/models';
import ModelPage from './pages/admin/model';
import CallbacksPage from './pages/admin/callbacks';
import CallbackPage from './pages/admin/callback';
import ProspectPage from './pages/admin/prospect';
import ProspectsPage from './pages/admin/prospects';
import ConfigPage from './pages/admin/config';

function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
    <Routes>
      <Route path="/" element={<Layout pageTitle="CW Detailing" />}>
        <Route index element={<Home />} />
        <Route path="detail" element={<Detail />}/>
        <Route path="/login" element={<LoginPage />} />
        {/* <Route path="/checkout/selectdate" element={<SelectDate />} /> */}
        <Route path="/workforus" element={<WorkForUs />} />
        
        {/* <Route
          path="/secret"
          element={
            <ProtectedRoute>
              <Secret />
            </ProtectedRoute>
          }
        /> */}
      </Route>
      <Route path = "/admin/" element={<ProtectedRoute><AdminLayout pageTitle=''></AdminLayout></ProtectedRoute>} >
      <Route path="/admin/models" element={<ModelsPage/>} ></Route>
      <Route path="/admin/model" element={<ModelPage/>} ></Route>
      <Route path="/admin/callbacks" element={<CallbacksPage/>} ></Route>
      <Route path="/admin/callback" element={<CallbackPage/>} ></Route>
      <Route path="/admin/prospects" element={<ProspectsPage/>} ></Route>
      <Route path="/admin/prospect" element={<ProspectPage/>} ></Route>
      <Route path="/admin/config" element={<ConfigPage/>} ></Route>
      </Route>
    </Routes>
    </AuthProvider>
  </BrowserRouter>
  );
}

export default App;
