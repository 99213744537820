import * as React from 'react'
import Box from '@mui/material/Box';

type Props = {
    backgroundColor?: string,
    children?: React.ReactNode
}

const PageContent = ({backgroundColor, children}: Props) => {
  return (
    <Box className="cf-page-wrapper"  sx={{ backgroundColor: backgroundColor }}>
      {children}
    </Box>
  )
}
export default PageContent