import React, { useState } from "react";
import Layout from '../components/layout'
import './app.scss';
import home_service from '../images/home_service.jpg'
import home_enginerebuilds from '../images/home_enginerebuilds.jpg'
import home_carboncleaning from '../images/home_carboncleaning.png'

import configData from "../config.json";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Alert, MenuItem, Select, Snackbar, Stack, styled, TextField } from "@mui/material/";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Dashboard, useUppyEvent } from "@uppy/react";
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import XHR from '@uppy/xhr-upload';
import axios from "axios";



const WorkForUs = () => {
  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [id, setId] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [workName, setWorkName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [mobile, setMobile] = React.useState<string>("");
  const [age, setAge] = React.useState<string>("");
  const [nationality, setNationality] = React.useState<string>("");
  const [height, setHeight] = React.useState<string>("");
  const [hourlyRate, setHourlyRate] = React.useState<string>("");
  const navigate = useNavigate();

  const [prospect, setProspect] = React.useState("");

  const [uppy] = useState(() => new Uppy({}).use(Webcam).use(XHR, {
    endpoint: configData.API_URL + "index.php/prospectImage/upload?XDEBUG_SESSION_START=PHPSTORM", onBeforeRequest: function (xhr: any) {
      //xhr.setRequestHeader('Authorization', (window as any).idToken);
      xhr.setRequestHeader('X-Prospect', (window as any).prospect);
    }
  }));


  const handleErrorMessageClose = (oEvent: any) => {
    setErrorMessageOpen(false)
  }

  const handleSuccessMessageClose = (oEvent: any) => {
    setSuccessMessageOpen(false)
  }

  const handleUploadComplete = (e: any) => {
    uppy.clear();
    setSuccessMessage("Request was successfully sent");
    setSuccessMessageOpen(true);
    navigate("/");
  };


  useUppyEvent(uppy, 'complete', handleUploadComplete)


  const handleSend = () => {
    var path = "index.php/prospect/publicAdd?prospect=" + prospect + "&XDEBUG_SESSION_START=PHPSTORM";


    var query = axios.post(
      configData.API_URL + path, {
      Name: name,
      WorkName: workName,
      Email: email,
      Mobile: mobile,
      Age: age,
      Nationality: nationality,
      Height: height,
      HourlyRate: hourlyRate
    }
    );

    query.then(function (responses: any) {
      setBusyIndicator(false);
      setProspect(responses.data.id);
      (window as any).prospect = responses.data.id;
      uppy.upload();
    })
      .catch(function (error: any) {
        setErrorMessage("We were unable to complete the save query");
        setErrorMessageOpen(true);
        setBusyIndicator(false);
        console.log(error);
      });

  };


  return (
    <div className="workForUsBox">

      <Box className="contentHolder" sx={{ marginTop: "0.2rem" }}>
        <Box sx={{ paddingLeft: "1rem" }} className="headlineWithLine">
          <img className="headerImage" src={require("../images/workforus.png")} style={{ height: "2.6rem" }} ></img>
          <p style={{ color: "white" }}>We are always on the lookout for reliable, confident and attractive escorts for work for our Bristol based escort agency.</p>

          <p style={{ color: "white" }}>If you are an escort or are considering working as one, are legal to work in this country and are over 18 years of age, please take a few moments to fill out this escort application form, and attach a couple of recent pictures.</p>

        </Box>
      </Box>

      <Stack className="workForUsForm" spacing={2}>

        <TextField variant="filled" label="Name"  value={name} onChange={(e) => setName(e.target.value)} />
        <TextField variant="filled" label="Work Name" value={workName} onChange={(e) => setWorkName(e.target.value)} />
        <TextField variant="filled" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField variant="filled" label="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
        <TextField variant="filled" label="Nationality" value={nationality} onChange={(e) => setNationality(e.target.value)} />
        <TextField variant="filled" label="Age" value={age} onChange={(e) => setAge(e.target.value)} />
        <TextField variant="filled" label="Height" value={height} onChange={(e) => setHeight(e.target.value)} />
        <TextField variant="filled" label="Hourly rate" value={hourlyRate} onChange={(e) => setHourlyRate(e.target.value)} />
        <Typography sx={{ color: "white" }}>Please provide up to six pictures</Typography>
        <Dashboard uppy={uppy} hideUploadButton={true} theme="dark" />

        <Button onClick={handleSend} variant="contained" >Send request</Button>
      </Stack>


      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessageOpen}
        onClose={handleErrorMessageClose}
        message={errorMessage}

      />

      <Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={handleSuccessMessageClose}>
        <Alert
          onClose={handleSuccessMessageClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

    </div>
  )
}
export default WorkForUs