import { useState } from "react";
import '../app.scss';

import DraggableList from "../../components/DraggableList";
import { Item } from "../../types/Item";
import { DropResult } from "@hello-pangea/dnd";
import React from "react";
import configData from "../../config.json";
import axios from "axios";
import { Button, Snackbar, Stack, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { firebase } from "../../components/firebase";
import { useAuth } from "../../hooks/useAuth";
import { getAuth } from "firebase/auth";

var baseItems: Item[] = []

type Model = {
    id: number;
    name: string;

    orderIndex: number;
    primary: string;
};


const ModelsPage = () => {
    const [busyIndicator, setBusyIndicator] = React.useState(false);
    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [models, setModels] = React.useState<Model[]>([]);
    const [modelsLoaded, setModelsLoaded] = React.useState(false);
    const [items, setItems] = useState(baseItems);
    let navigate = useNavigate();


    const handleErrorMessageClose = (oEvent: any) => {
        setErrorMessageOpen(false)
    }

    const handleAdd = (oEvent: any) => {
        navigate("/admin/model");
    }

    const handleDelete = (item: any) => {
        var user = getAuth().currentUser;
        user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
            var query = axios.post(
                configData.API_URL + "index.php/model/delete?id=" + item.id, {}, {
                    headers: {
                        Authorization: idToken
                    }
                }
            );

            query.then(function (responses: any) {
                setBusyIndicator(false);
                setModelsLoaded(false);

            })
                .catch(function (error: any) {
                    setErrorMessage("We were unable to complete the reorder query");
                    setErrorMessageOpen(true);
                    setBusyIndicator(false);
                    console.log(error);
                });
        });
    }

    const handleEdit = (item: any) => {
        navigate("/admin/model?id=" + item.id);
    }

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const result = Array.from(items);
        const [removed] = result.splice(source.index, 1);
        result.splice(destination.index, 0, removed);
        setItems(result);

        var sourceItem = items[source.index];
        var user = getAuth().currentUser;
        user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {

            var query = axios.post(
                configData.API_URL + "index.php/model/reorder?id=" + sourceItem.id + "&order=" + destination.index + "&XDEBUG_SESSION_START=PHPSTORM", { }, {
                    headers: {
                        Authorization: idToken
                    }
                }
            );

            query.then(function (responses: any) {
                setBusyIndicator(false);
                setModelsLoaded(false);

            })
                .catch(function (error: any) {
                    setErrorMessage("We were unable to complete the reorder query");
                    setErrorMessageOpen(true);
                    setBusyIndicator(false);
                    console.log(error);
                });
        });
    };


    React.useEffect(() => {
        setBusyIndicator(true);
        var user = getAuth().currentUser;
        user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
            var query = axios.get(
                configData.API_URL + "index.php/model/list" + "?limit=100&XDEBUG_SESSION_START=PHPSTORM", {
                headers: {
                    Authorization: idToken
                }
            }
            );
            query.then(function (responses: any) {
                setBusyIndicator(false);
                setModelsLoaded(true);
                var items = []
                for (let i = 0; i < responses.data.length; i++) {
                    const element = responses.data[i];
                    items.push({ primary: element.name, id: "" + element.id, secondary: "", image: element.mainImage })
                }
                setItems(items);
                setModels(responses);
            })
                .catch(function (error: any) {
                    setErrorMessage("We were unable to complete the catgories query");
                    setErrorMessageOpen(true);
                    setBusyIndicator(false);
                    console.log(error);
                });

        }).catch(function (error: any) {
            setErrorMessage("We were unable to complete the catgories query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
        });

    }, [modelsLoaded]);



    return (
        <Stack sx={{ marginLeft: "1rem", marginRight: "1rem" }}>




            <Stack direction="row">
                <Typography sx={{ flex: "1 1" }} gutterBottom variant="h5">Escorts</Typography>
                <Button sx={{ marginRight: "2rem" }} onClick={handleAdd} variant="contained">Add</Button>
            </Stack>



            <DraggableList items={items} onDragEnd={onDragEnd} onEdit={handleEdit} onDelete={handleDelete} />

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={errorMessageOpen}
                onClose={handleErrorMessageClose}
                message={errorMessage}

            />

        </Stack>

    )
}
export default ModelsPage