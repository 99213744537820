import { useState } from "react";
import '../app.scss';

import DraggableList from "../../components/DraggableList";
import { Item } from "../../types/Item";
import { DropResult, ResponderProvided } from "@hello-pangea/dnd";
import React from "react";
import configData from "../../config.json";
import axios from "axios";
import { Alert, Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Link, MenuItem, OutlinedInput, Select, Snackbar, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Webcam from "@uppy/webcam";
import XHR from '@uppy/xhr-upload';
import Uppy, { Meta, UppyFile } from "@uppy/core";
import { Dashboard, useUppyEvent } from "@uppy/react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DraggableListImage from "../../components/DraggableListImage";
import { ModelImage } from "../../types/ModelImage";
import { getAuth } from "firebase/auth";

var baseItems: ModelImage[] = [];

const ConfigPage = () => {

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();



  const [idToken, setIdToken] = React.useState("");


  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [name, setName] = React.useState<string>("");
  const [modelName, setModelName] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [requestedDateTime, setRequestedDateTime] = React.useState<string>("");
  const [duration, setDuration] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [comments, setComments] = React.useState<string>("");

  const handleErrorMessageClose = (oEvent: any) => {
    setErrorMessageOpen(false)
  }

  const handleSuccessMessageClose = (oEvent: any) => {
    setSuccessMessageOpen(false)
  }

  const handleClose = () => {

    //navigate("/admin/callbacks");

  };


  const checkNotificationPermission = () => {
    return new Promise((resolve, reject) => {
      if (Notification.permission === 'denied') {
        return reject(new Error('Push messages are blocked.'));
      }

      if (Notification.permission === 'granted') {
        return resolve(null);
      }

      if (Notification.permission === 'default') {
        return Notification.requestPermission().then(result => {
          if (result !== 'granted') {
            reject(new Error('Bad permission result'));
          } else {
            resolve(null);
          }
        });
      }

      return reject(new Error('Unknown permission'));
    });
  }

  function arrayBufferToBase64( buffer: any ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


  const push_sendSubscriptionToServer = (subscription: any) => {
    const key = subscription.getKey('p256dh');
    const token = subscription.getKey('auth');
    const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

    var query = axios.post(
      configData.API_URL + "index.php/subscription/add", {
        subscriptionEndpoint: subscription.endpoint,
        subscriptionKey: key ? arrayBufferToBase64(key) : null,
        subscriptionToken: token ? arrayBufferToBase64(token) : null,
        contentEncoding: contentEncoding
      }, {
      headers: {
        Authorization: idToken
      },
    }
    );

    return query.then(function (responses: any) {
      setBusyIndicator(false);
      return "";
    })
      .catch(function (error: any) {
        setErrorMessage("We were unable to subscribe");
        setErrorMessageOpen(true);
        setBusyIndicator(false);
        console.log(error);
      });

    // return fetch('push_subscription.php', {
    //   method,
    //   body: JSON.stringify({
    //     endpoint: subscription.endpoint,
    //     publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
    //     authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
    //     contentEncoding,
    //   }),
    // }).then(() => subscription);
  }



  const urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const handleSubscribe = () => {
    var user = getAuth().currentUser;
    user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
      var query = axios.get(
        configData.API_URL + "index.php/subscription/key", {
        headers: {
          Authorization: idToken
        },
      }
      );

      query.then(function (responses: any) {
        setBusyIndicator(false);

        return checkNotificationPermission()
          .then(() => { 
            return navigator.serviceWorker.ready;
           })
          .then(serviceWorkerRegistration =>{
            return serviceWorkerRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(responses.data.key),
            });
      })
          .then(subscription => {
            // Subscription was successful
            // create subscription on your server
            return push_sendSubscriptionToServer(subscription);
          })
          .then(subscription => {
            if (subscription) {
              setSuccessMessage("Subscription added");
              setSuccessMessageOpen(true);
            }
          }) // update your UI
          .catch(e => {
            if (Notification.permission === 'denied') {
              // The user denied the notification permission which
              // means we failed to subscribe and the user will need
              // to manually change the notification permission to
              // subscribe to push messages
              console.warn('Notifications are denied by the user.');
              setErrorMessage("Notifications were not allowed");
              setErrorMessageOpen(true);
            } else {
              // A problem occurred with the subscription; common reasons
              // include network errors or the user skipped the permission
              console.error('Impossible to subscribe to push notifications', e);
              setErrorMessage("Unable to subscribe to push notifications ");
              setErrorMessageOpen(true);
            }
          });

      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the callbacks query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });
    })
    //navigate("/admin/callbacks");

  };

  const handleUnsubcribe = () => {

    //navigate("/admin/callbacks");

  };


  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
        (window as any).idToken = idToken;
        var query = axios.get(
          configData.API_URL + "index.php/callback/get?id=" + id, {
          headers: {
            Authorization: idToken
          },
        }
        );

        query.then(function (responses: any) {
          setBusyIndicator(false);
          setLoaded(true);
          setName(responses.data.name);
          setModelName(responses.data.modelName);
          setPhone(responses.data.phone);
          setEmail(responses.data.email);
          setRequestedDateTime(responses.data.requestedDateTime);
          setDuration(responses.data.duration);
          setComments(responses.data.comments);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the callbacks query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
    else {
      setLoaded(true);
    }


  }, [loaded]);


  const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: "0.25rem"
  }));

  return (
    <Stack sx={{ marginLeft: "1rem", marginRight: "1rem" }}>

      <Stack direction="row" spacing={1} sx={{ marginTop: "1rem" }}>
        <Typography gutterBottom sx={{ flex: "1 1" }} variant="h5">Configuration</Typography>
      </Stack>

      <Button variant="outlined" onClick={handleSubscribe}>Subscribe</Button>
      <Button variant="outlined" onClick={handleUnsubcribe}>Unsubscribe</Button>


      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessageOpen}
        onClose={handleErrorMessageClose}
        message={errorMessage}

      />

      
<Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={handleSuccessMessageClose}>
        <Alert
          onClose={handleSuccessMessageClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

    </Stack>

  )
}
export default ConfigPage