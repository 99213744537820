import { useState } from "react";

import {
  AppBar,
  Container,
  Toolbar,
  Paper,
  TextField,
  Grid,
  Typography,
  Link,
  Button,
  Box,
  Select,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";
import "./login.css";
import { useForm } from "react-hook-form";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { firebase } from "../components/firebase";
import PageContainer from "../components/pagecontainer";
import PageContent from "../components/pagecontent";
import PageHeader from "../components/pageheader";
import { useNavigate, useSearchParams } from "react-router-dom";


const LoginPage = () => {

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [searchParams] = useSearchParams();
  
  let navigate = useNavigate();

  const signInUser = (event: any) => {
    const auth = getAuth(firebase);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if(searchParams.get("redirectUri"))
        {
          navigate(decodeURIComponent(decodeURIComponent(searchParams.get("redirectUri")!!).replace("??", "?")));
        }
        else
        {
          navigate("/admin/callbacks");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMessage(errorMessage);
        setErrorMessageOpen(true);
      });

  };


  return (
    <PageContainer>

      <PageContent>
        <PageHeader>Login</PageHeader>
        <Paper elevation={3}>
          <Box sx={{ padding: "1.6rem" }}>
            <Box>
              <Stack spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Sign in
                </Typography>

                <Typography gutterBottom>Sign in your account.</Typography>

                <TextField
                  required
                  label="Email"
                  fullWidth
                  variant="outlined"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  required
                  label="Password"
                  fullWidth
                  type="password"
                  variant="outlined"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                />

                <Button onClick={signInUser} variant="contained">Sign</Button>
              </Stack>
            </Box>
          </Box>
        </Paper>
      </PageContent>
    </PageContainer>
  );
};
export default LoginPage;
