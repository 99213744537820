import * as React from 'react'
import { Outlet } from "react-router-dom";
import { useState } from 'react';


import Box from '@mui/material/Toolbar';
import NavBar from './navbar';
import Footer from './footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const pageStyles = {
    color: "#232129",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
  }
  

type Props = {
    pageTitle: string
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px var(--primary-weak) inset",
            "-webkit-text-fill-color": "var(--text-primary)",
          },
        },
      },
    },
  }
});


const Layout = ({ pageTitle }: Props) => {
  const [showMainContent, setShowMainContent] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);

  return (
    <ThemeProvider theme={darkTheme}>
    <div style={pageStyles}>
      <title>{pageTitle}</title>
      

<NavBar></NavBar>
 
<Box sx={{ height: 90  }} ></Box>

{showMainContent && <div>

      <main>
      <Outlet />
      </main>

</div>}
    </div>
    </ThemeProvider>
  )
}
export default Layout