import { getAuth } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

type Props = {
  children?: React.ReactNode
}

export const ProtectedRoute = ({ children }: Props) => {
  var user = getAuth().currentUser;

  const location = useLocation();

  if (!user) {
    // user is not authenticated
    var locationUri = encodeURIComponent(location.pathname);
    if(location.search)
    {
        locationUri += "?" + location.search;
    }
    var loginUri = "/login?redirectUri=" + encodeURIComponent(locationUri);
    return <Navigate to={loginUri}  />;
  }
  return <>{children}</>;
};