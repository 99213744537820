import React, { FC } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Tooltip,
  ListItemButton,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Item } from '../types/Item';
import configData from "../config.json";

export type Props = {
  item: Item;
  index: number;
  onEdit: (e: any) => void;
  onDelete: (e: any) => void;
};

const DraggableListItem: FC<Props> = ({ item, index, onEdit, onDelete }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <Tooltip title="Drag Me!">
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
          >
            <ListItemAvatar>
              <Avatar src={configData.API_URL + item.image}>

              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.primary} secondary={item.secondary} />
            <ListItemButton onClick={(e) => { onEdit(item) }} sx={{ flex: "0 0 5%", justifyContent: "center" }}> <EditIcon /></ListItemButton>
            <ListItemButton onClick={(e) => { onDelete(item) }} sx={{ flex: "0 0 5%", justifyContent: "center" }}><DeleteIcon /> </ListItemButton>
          </ListItem>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
