import React, { FC } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  Tooltip,
  ListItemButton,
  Radio,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModelImage } from '../types/ModelImage';
import configData from "../config.json";

export type Props = {
  item: ModelImage;
  index: number;
  mainPath: string;
  onSelectMain: (path: string) => void;
  onDelete: (e: any) => void;
};

const DraggableListImageItem: FC<Props> = ({ item, index, mainPath, onSelectMain, onDelete }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <Tooltip title="Drag Me!">
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
          >
            <ListItemAvatar>
              <Box
                component="img"
                sx={{
                  height: 200,
                }}
                src={configData.API_URL + item.path}
              />
            </ListItemAvatar>
            <ListItemButton onClick={(e) => { onDelete(item) }} sx={{ flex: "0 0 5%", justifyContent: "center" }}><DeleteIcon /> </ListItemButton>
            <Radio
  checked={mainPath === item.path}
  onChange={(e)=> onSelectMain(item.path)}
  name="mainItem"
/>
          </ListItem>
        </Tooltip>
      )}
    </Draggable>
  );
};

export default DraggableListImageItem;
