import * as React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  children?: React.ReactNode
}

const PageHeader  = ({children}: Props) => {

  return (
    <Box className="cf-page-header" sx={{ marginBottom: "1.6rem" }} >
          <Typography
            sx={{ fontWeight: "bold", color: "white" }}
            component="h1"
            variant="h4"
            className="cf-page-title"
            align="center">
            {children}
          </Typography>
        </Box>
  )
}
export default PageHeader