import React, { RefObject, useEffect, useRef, useState } from 'react';
import Layout from "../components/layout";
import "./app.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import configData from "../config.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import PageContainer from "../components/pagecontainer";
import PageContent from "../components/pagecontent";
import PageHeader from "../components/pageheader";
import PageImage from "../components/pageimage";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ImageList, ImageListItem, MenuItem, Select, Snackbar, Stack, styled, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material/';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { Dayjs } from 'dayjs';

const Detailing = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [id, setId] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [age, setAge] = React.useState<string>("");
  const [nationality, setNationality] = React.useState<string>("");
  const [stats, setStats] = React.useState<string>("");
  const [height, setHeight] = React.useState<string>("");
  const [languages, setLanguages] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [mainImage, setMainImage] = React.useState<string>("");
  const [active, setActive] = React.useState<boolean>(false);
  const [images, setImages] = useState([]);

  const [callbackName, setCallbackName] = React.useState<string>("");
  const [callbackPhone, setCallbackPhone] = React.useState<string>("");
  const [callbackEmail, setCallbackEmail] = React.useState<string>("");
  const [callbackDate, setCallbackDate] = React.useState<Dayjs>();
  const [callbackTime, setCallbackTime] = React.useState<Dayjs>();
  const [callbackDuration, setCallbackDuration] = React.useState<string>("");
  const [callbackLocation, setCallbackLocation] = React.useState<string>("");
  const [callbackComment, setCallbackComment] = React.useState<string>("");

  const handleErrorMessageClose = (oEvent: any) => {
    setErrorMessageOpen(false)
  }

  const handleSuccessMessageClose = (oEvent: any) => {
    setSuccessMessageOpen(false)
  }

  const settings = {
    arrows: false,
    dots: false
  };

  const [nav1, setNav1] = useState<RefObject<Slider>>();
  const [nav2, setNav2] = useState<RefObject<Slider>>();
  let sliderRef1 = React.useRef<Slider>(null);;
  let sliderRef2 = React.useRef<Slider>(null);;


  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleAddCallback  = () => {

      var path = "index.php/callback/publicAdd";

      const dateTime = callbackDate!!.set('hour', callbackTime!!.hour())
      .set('minute', callbackTime!!.minute())
      .set('second', callbackTime!!.second());

      var query = axios.post(
        configData.API_URL + path, {
        Name: callbackName,
        Phone: callbackPhone,
        Email: callbackEmail,
        RequestedDateTime: dateTime.format("DD/MM/YYYY hh:mm:ss A"),
        Duration: callbackDuration,
        Location: callbackLocation,
        Comment: callbackComment,
        ModelId: id,
        ModelName: name
      }
      );

      query.then(function (responses: any) {
        setBusyIndicator(false);
        setOpen(false);
        setSuccessMessage("The callback request has been sent");
        setSuccessMessageOpen(true);
      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the callback query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });

  };



  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);
      var query = axios.get(
        configData.API_URL + "index.php/model/publicGet?id=" + id, {}
      );

      query.then(function (responses: any) {
        setBusyIndicator(false);
        setLoaded(true);
        setId(responses.data.id);
        setName(responses.data.name);
        setDescription(responses.data.description);
        setAge(responses.data.age);
        setNationality(responses.data.nationality);
        setStats(responses.data.stats);
        setHeight(responses.data.height);
        setLanguages(responses.data.languages);
        setLocation(responses.data.location);
        setCategory(responses.data.category);
        setMainImage(responses.data.mainImage)
        setActive(responses.data.active);
      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the models query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });
    }
    else {
      setLoaded(true);
    }


  }, [loaded]);



  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);
      var query2 = axios.get(
        configData.API_URL + "index.php/modelImage/findByModel?model=" + id + "&XDEBUG_SESSION_START=PHPSTORM", {}
      );

      query2.then(function (responses: any) {
        setBusyIndicator(false);

        setImages(responses.data);
        setImagesLoaded(true);
      })
        .catch(function (error: any) {
          setErrorMessage("We were unable to complete the model images query");
          setErrorMessageOpen(true);
          setBusyIndicator(false);
          console.log(error);
        });
    }
    else {
      setLoaded(true);
    }


  }, [imagesLoaded]);

  return (
    <PageContainer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>I'd like to see: {name}</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <TextField label="Your Name" variant="filled" value={callbackName} onChange={(e) => setCallbackName(e.target.value)} />
              <TextField label="Phone" variant="filled" value={callbackPhone} onChange={(e) => setCallbackPhone(e.target.value)} />
              <TextField label="Email" variant="filled" value={callbackEmail} onChange={(e) => setCallbackEmail(e.target.value)} />
              <DatePicker label="Date" value={callbackDate} onChange={(value) => setCallbackDate(value!!)} />
              <TimePicker label="Time" value={callbackTime} onChange={(value) => setCallbackTime(value!!)} />
              <Select value={callbackDuration} onChange={(e) => setCallbackDuration(e.target.value)}
                label="Duration"
              >
                <MenuItem value="30 minutes">30 minutes</MenuItem>
                <MenuItem value="1 hour">1 hour</MenuItem>
                <MenuItem value="2 hours">2 hours</MenuItem>
                <MenuItem value="3 hours">3 hours</MenuItem>
                <MenuItem value="4 hours"> 4 hours</MenuItem>
                <MenuItem value="Overnight">Overnight</MenuItem>
              </Select>
              <TextField value={callbackLocation} onChange={(e) => setCallbackLocation(e.target.value)} id="standard-basic" label="Location" variant="standard" multiline rows={4} />
              <TextField value={callbackComment} onChange={(e) => setCallbackComment(e.target.value)} id="standard-basic" label="Anything else we should know" variant="standard" multiline rows={4} />
            </Stack>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >

            </Box>
          </DialogContent>
          <DialogActions>
                      
          <Button onClick={handleAddCallback}>Request callback</Button>
          <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <PageContent>

          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>

            <ImageList variant="masonry" cols={2} gap={2}>
        {images.map((item: any) => (
          <ImageListItem key={item.path}>
            <Box component="img" sx={{ borderRadius: "1rem" }}
              src={configData.API_URL + item.path}
              alt={item.path}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
              

              {/* <Slider asNavFor={(nav2?.current as Slider)} ref={sliderRef1} {...settings}>
              {images.map((item: any, index: number) => (
                <CardMedia
                sx={{ height: 600 }}
                image={configData.API_URL + item.path}
              />
              ))}
              </Slider>
              <Slider
                asNavFor={(nav1?.current as Slider)}
                ref={sliderRef2}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                dots={false}
              >
                {images.map((item: any, index: number) => (
                  <CardMedia
                    sx={{ height: 180 }}
                    image={configData.API_URL + item.path}
                  />
                ))}
              </Slider> */}

            </Grid>

            <Grid item xs={12} md={4}>

            <Typography gutterBottom variant="h6" component="div" sx={{ color: "white", borderBottom: "1px solid #d3a744", marginTop: "2rem", textTransform: "uppercase" }}>
            {name}
          </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ color: "white" }}>
                {description}
              </Typography>

              <Typography gutterBottom variant="h6" component="div" sx={{ color: "white", borderBottom: "1px solid #d3a744", marginTop: "2rem" }}>
                Statistics
              </Typography>

              <TableContainer>
                <Table aria-label="simple table">

                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >Age</TableCell>
                      <TableCell align="right" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >{age}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >Nationality</TableCell>
                      <TableCell align="right" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >{nationality}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >Stats</TableCell>
                      <TableCell align="right" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >{stats}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >Height</TableCell>
                      <TableCell align="right" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >{height}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >Languages</TableCell>
                      <TableCell align="right" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >{languages}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >Location</TableCell>
                      <TableCell align="right" sx={{ color: "white", borderBottom: "none", padding: "0.3rem" }} >{location}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>



              <Stack justifyContent="center" alignItems="center">
                <Button size="small" onClick={handleClickOpen} variant="contained" sx={{ backgroundColor: "#a68741", fontWeight: 700, textTransform: "none", fontSize: "1.2rem", width: "15rem", marginTop: "2rem" }}>Request a callback</Button>
              </Stack>

            </Grid>
          </Grid>


          <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={errorMessageOpen}
                onClose={handleErrorMessageClose}
                message={errorMessage}

            />

<Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={handleSuccessMessageClose}>
        <Alert
          onClose={handleSuccessMessageClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>


        </PageContent>
      </LocalizationProvider>
    </PageContainer>
  );
};
export default Detailing;
