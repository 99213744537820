import React, { FC } from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import { List } from '@mui/material';
import { Item } from '../types/Item';

export type Props = {
  items: Item[];
  onDragEnd: OnDragEndResponder;
  onEdit: (e: any) => void;
  onDelete: (e: any) => void;
};

const DraggableList: FC<Props> = React.memo(({ items, onDragEnd, onEdit, onDelete }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item: Item, index: number) => (
              <DraggableListItem item={item} index={index} key={item.id} onEdit={onEdit} onDelete={onDelete}  />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
