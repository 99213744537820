import { useState } from "react";
import '../app.scss';

import DraggableList from "../../components/DraggableList";
import { Item } from "../../types/Item";
import { DropResult } from "@hello-pangea/dnd";
import React from "react";
import configData from "../../config.json";
import axios from "axios";
import { Avatar, Button, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Snackbar, Stack, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { firebase } from "../../components/firebase";
import { useAuth } from "../../hooks/useAuth";
import { getAuth } from "firebase/auth";
import InboxIcon from '@mui/icons-material/Inbox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export type Callback = {
    phone: string;
    requestedDateTime: string;
    duration: string;
    location: string;
    createdDate: string;
    id: string;
    primary: string;
    secondary: string;
    modelName: string;
  };
  

var baseItems: Callback[] = []


const CallbacksPage = () => {
    const [busyIndicator, setBusyIndicator] = React.useState(false);
    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [callbacksLoaded, setCallbacksLoaded] = React.useState(false);
    const [items, setItems] = useState(baseItems);
    let navigate = useNavigate();


    const handleErrorMessageClose = (oEvent: any) => {
        setErrorMessageOpen(false)
    }

    const handleDelete = (item: any) => {
        var user = getAuth().currentUser;
        user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
            var query = axios.post(
                configData.API_URL + "index.php/callback/delete?id=" + item.id, {}, {
                headers: {
                    Authorization: idToken
                }
            }
            );

            query.then(function (responses: any) {
                setBusyIndicator(false);
                setCallbacksLoaded(false);

            })
                .catch(function (error: any) {
                    setErrorMessage("We were unable to complete the delete query");
                    setErrorMessageOpen(true);
                    setBusyIndicator(false);
                    console.log(error);
                });
        });
    }

    const handleEdit = (item: any) => {
        navigate("/admin/callback?id=" + item.id);
    }


    React.useEffect(() => {
        setBusyIndicator(true);
        var user = getAuth().currentUser;
        user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
            var query = axios.get(
                configData.API_URL + "index.php/callback/list" + "?XDEBUG_SESSION_START=PHPSTORM", {
                headers: {
                    Authorization: idToken
                }
            }
            );

            query.then(function (responses: any) {
                setBusyIndicator(false);
                setCallbacksLoaded(true);
                var items: Callback[] = [];
                for (let i = 0; i < responses.data.length; i++) {
                    const element = responses.data[i];
                    items.push({ 
                        id: "" + element.id, 
                        primary: element.name, 
                        secondary: "", 
                        phone: element.phone, 
                        requestedDateTime: element.requestedDateTime, 
                        duration: element.duration, 
                        location: element.location, 
                        createdDate: element.createdDate,
                        modelName: element.modelName })
                }
                setItems(items);
            })
                .catch(function (error: any) {
                    setErrorMessage("We were unable to complete the callbacks query");
                    setErrorMessageOpen(true);
                    setBusyIndicator(false);
                    console.log(error);
                });

        }).catch(function (error: any) {
            setErrorMessage("We were unable to complete the callbacks query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
        });

    }, [callbacksLoaded]);



    return (
        <Stack sx={{ marginLeft: "1rem", marginRight: "1rem" }}>




            <Stack direction="row">
                <Typography sx={{ flex: "1 1" }} gutterBottom variant="h5">Callbacks</Typography>
            </Stack>



            <List>
                {items.map((item: Callback, index: number) => (
                    <ListItem
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <InboxIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<React.Fragment><Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{item.primary}</Typography></React.Fragment>}  secondary={
            <React.Fragment>
                        <Stack direction="row"><Typography>Phone: </Typography>&nbsp;<Link sx={{ fontSize: "1rem", fontWeight: 600 }} href={"tel:" + item.phone} >{item.phone}</Link></Stack>
                        <Stack direction="row"><Typography>Model:</Typography>&nbsp;<Typography sx={{ fontWeight: 600}}>{item.modelName}</Typography></Stack>
                        <Stack direction="row"><Typography>Date/Time:</Typography>&nbsp;<Typography sx={{ fontWeight: 600}}>{item.requestedDateTime}</Typography></Stack>
                        <Stack direction="row"><Typography>Duration:</Typography>&nbsp;<Typography sx={{ fontWeight: 600}}>{item.duration}</Typography></Stack>
                        <Stack direction="row"><Typography>Location:</Typography>&nbsp;<Typography sx={{ fontWeight: 600}}>{item.location}</Typography></Stack>
                        <Stack direction="row"><Typography>Sent:</Typography>&nbsp;<Typography sx={{ fontWeight: 600}}>{item.createdDate}</Typography></Stack>
                        
            </React.Fragment>
          } />
                        <ListItemButton onClick={(e) => { handleEdit(item) }} sx={{ flex: "0 0 5%", justifyContent: "center" }}> <EditIcon /></ListItemButton>
                        <ListItemButton onClick={(e) => { handleDelete(item) }} sx={{ flex: "0 0 5%", justifyContent: "center" }}><DeleteIcon /> </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={errorMessageOpen}
                onClose={handleErrorMessageClose}
                message={errorMessage}

            />

        </Stack>

    )
}
export default CallbacksPage