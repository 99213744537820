import * as React from 'react';
import './navbar.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from "react-router-dom";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { SignInPopup } from './signinpopup';

import { useAuth } from '../hooks/useAuth';

type Page = {
    name:string,
    location: string,
    active: boolean
}



type ArrayOf<T> = T[];

const pages: ArrayOf<Page> = [
    {name:"Performance Parts", location: "/performanceparts", active: true}, 
  {name:"Wash Club", location: "/washclub", active: false}, 
  {name:"Single Wash", location: "/singlewash", active: false}, 
  {name:"Wash Pass", location: "/washpass", active: false}, 
  {name:"Fleet Account", location: "/fleetaccount", active: false}];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const NavBar = () => {
  const [signInPopupOpen, setSignInPopupSetOpen] = React.useState(false);
  let navigate = useNavigate();
  const handleWorkForUs = () => {
    navigate("/workforus")
  };
  
  
  return (
    <AppBar sx={{ bgcolor: "black" }} elevation={0}>
    <Container maxWidth="xl">
              <SignInPopup open={signInPopupOpen} setOpen={setSignInPopupSetOpen} ></SignInPopup>

      <Toolbar className="cf-appbar-toolbar" disableGutters >

        

        <Box sx={{ flexGrow: 1 }} >

        <a className="logo"  href="/">
<img className="logo"  src={require("../images/logo.png")}  ></img>

</a>


        </Box>
         
        <Button  size="small" onClick={handleWorkForUs} variant="contained" sx={{  backgroundColor: "#a68741", fontWeight: 700, textTransform: "none", fontSize: "1.2rem", display: { xs: 'none', md: 'flex' } }}>Work with us</Button>

      </Toolbar>
    </Container>
  </AppBar>
  )
}
export default NavBar