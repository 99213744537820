import * as React from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from 'react';


import Box from '@mui/material/Toolbar';
import NavBar from './navbar';
import Footer from './footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Container, IconButton, Menu, MenuItem, Typography, Button, Tooltip, Avatar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { getAuth, signOut } from 'firebase/auth';

const pageStyles = {
  backgroundColor: "white",
  minHeight: "100vh",
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


type Props = {
  pageTitle: string
}

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

var pages = [
  { name: "Callbacks", url: "/admin/callbacks" },
  { name: "Escorts", url: "/admin/models" },
  { name: "Prospects", url: "/admin/prospects" },
  { name: "Config", url: "/admin/config" }]

var pagesMobile = [
  { name: "Callbacks", url: "/admin/callbacks" },
  { name: "Escorts", url: "/admin/models" },
  { name: "Prospects", url: "/admin/prospects" },
  { name: "Config", url: "/admin/config" },
  { name: "Logout", url: "/admin/logout" }]



const AdminLayout = ({ pageTitle }: Props) => {
  const [showMainContent, setShowMainContent] = useState(true);
  let navigate = useNavigate();


  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page: any) => {
    if(page.name == "Logout")
    {
      handleLogout();
    }
    else{
      navigate(page.url);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      navigate("/login");
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div style={pageStyles}>
        <title>{pageTitle}</title>


        <AppBar position="static" sx={{ backgroundColor: "black" }} >
          <Container maxWidth="xl">
            <Toolbar disableGutters>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: 'block', md: 'none' } }}
                >
                  {pagesMobile.map((page) => (
                    <MenuItem key={page.url} onClick={() => handleCloseNavMenu(page)}>
                      <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>

              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page.url}
                    onClick={() => handleCloseNavMenu(page)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
              <Button sx={{ color: "white", display: { xs: 'none', md: 'flex' } }} onClick={handleLogout} >Logout</Button>
              </Toolbar>
          </Container>
        </AppBar>

        <Box sx={{ height: 90 }} ></Box>

        {showMainContent && <div>

          <main>
            <Outlet />
          </main>

        </div>}
      </div>
    </ThemeProvider>
  )
}
export default AdminLayout