import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useState, SetStateAction } from 'react';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type Props = {
    open: boolean,
    setOpen: React.Dispatch<SetStateAction<boolean>>
}


export const SignInPopup = ({ open, setOpen }: Props) => {
  const handleClose = () => {
    setOpen(false);
  };


  const { handleSubmit, register } = useForm();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const schema = {
    //email: Joi.string().email().required(),
    //password: Joi.string().required(),
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Sign up
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Typography gutterBottom>Sign in your account.</Typography>

          <TextField 
          required 
          label="Email" 
          fullWidth 
          type="email"
          autoComplete="email"
          variant="outlined"
          //onChange={(e) => fieldChange("email", e.target.value)}
          //error={!!errors.email}
          //helperText={errors.email?.message}
          />

          <TextField
            required
            label="Password"
            fullWidth
            type="password"
            autoComplete="current-password"
            variant="outlined"
            //onChange={(e) => fieldChange("password", e.target.value)}
            //error={!!errors.email}
            //helperText={errors.email?.message}
          />

          <Link href="#" underline="hover">
            Forgot password?
          </Link>

          <Button variant="contained">Sign in now</Button>

          <Box>
            <Stack direction="row" spacing={2}>
              <Typography>Don't have an account?</Typography>
              <Link>Sign up now</Link>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
};
