import { useState } from "react";
import '../app.scss';

import DraggableList from "../../components/DraggableList";
import { Item } from "../../types/Item";
import { DropResult, ResponderProvided } from "@hello-pangea/dnd";
import React from "react";
import configData from "../../config.json";
import axios from "axios";
import { Alert, Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Link, List, ListItem, ListItemAvatar, MenuItem, OutlinedInput, Select, Snackbar, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Webcam from "@uppy/webcam";
import XHR from '@uppy/xhr-upload';
import Uppy, { Meta, UppyFile } from "@uppy/core";
import { Dashboard, useUppyEvent } from "@uppy/react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DraggableListImage from "../../components/DraggableListImage";
import { ModelImage } from "../../types/ModelImage";
import { getAuth } from "firebase/auth";

var baseItems: ModelImage[] = [];

const ProspectPage = () => {

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();



  const [idToken, setIdToken] = React.useState("");

  const [busyIndicator, setBusyIndicator] = React.useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessageOpen, setSuccessMessageOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const [name, setName] = React.useState<string>("");
  const [workName, setWorkName] = React.useState<string>("");
  const [mobile, setMobile] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [nationality, setNationality] = React.useState<string>("");
  const [age, setAge] = React.useState<string>("");
  const [height, setHeight] = React.useState<string>("");
  const [hourlyRate, setHourlyRate] = React.useState<string>("");
  const [createdDate, setCreatedDate] = React.useState<string>("");
  const [images, setImages] = useState(baseItems);

  const handleErrorMessageClose = (oEvent: any) => {
    setErrorMessageOpen(false)
  }

  const handleClose = () => {

    navigate("/admin/prospects");

  };


  const handleSuccessMessageClose = (oEvent: any) => {
    setSuccessMessageOpen(false)
  }

  const handleConvertToModel = () => {

    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
        (window as any).idToken = idToken;
        var query = axios.post(
          configData.API_URL + "index.php/prospect/convertToModel?id=" + id, {}, {
          headers: {
            Authorization: idToken
          },
        }
        );

        query.then(function (responses: any) {
          setBusyIndicator(false);
          setSuccessMessage("Prospect has been converted to a model");
          setSuccessMessageOpen(true);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the prospects query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
  };


  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {
        (window as any).idToken = idToken;
        var query = axios.get(
          configData.API_URL + "index.php/prospect/get?id=" + id, {
          headers: {
            Authorization: idToken
          },
        }
        );

        query.then(function (responses: any) {
          setBusyIndicator(false);
          setLoaded(true);
          setName(responses.data.name);
          setWorkName(responses.data.workName);
          setMobile(responses.data.mobile);
          setEmail(responses.data.email);
          setNationality(responses.data.nationality);
          setAge(responses.data.age);
          setHeight(responses.data.height);
          setHourlyRate(responses.data.hourlyRate);
          setCreatedDate(responses.data.createdDate);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the prospects query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
    else {
      setLoaded(true);
    }


  }, [loaded]);

  
  React.useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      setBusyIndicator(true);

      var user = getAuth().currentUser;
      user!!.getIdToken(/* forceRefresh */ true).then(function (idToken: string) {

        var query2 = axios.get(
          configData.API_URL + "index.php/prospectImage/findByProspect?prospect=" + id + "&XDEBUG_SESSION_START=PHPSTORM", {
          headers: {
            Authorization: idToken
          },
        }
        );

        query2.then(function (responses: any) {
          setBusyIndicator(false);
          var items = []
          for (let i = 0; i < responses.data.length; i++) {
            const element = responses.data[i];
            items.push({ id: "" + element.id, path: element.path, description: element.description, orderIndex: element.orderIndex })
          }
          setImages(items);
          setImagesLoaded(true);
        })
          .catch(function (error: any) {
            setErrorMessage("We were unable to complete the model images query");
            setErrorMessageOpen(true);
            setBusyIndicator(false);
            console.log(error);
          });
      })
    }
    else {
      setLoaded(true);
    }


  }, [imagesLoaded]);


  const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: "0.25rem"
  }));

  return (
    <Stack sx={{ marginLeft: "1rem", marginRight: "1rem" }}>

      <Stack direction="row" spacing={1} sx={{ marginTop: "1rem" }}>
        <Typography gutterBottom sx={{ flex: "1 1" }} variant="h5">Model: {name}</Typography>
        <Button variant="outlined" onClick={handleClose}>Close</Button>
      </Stack>

      <Grid container spacing={3} sx={{ paddingLeft: "1rem", marginTop: "0.5rem" }}>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Name
          </FormLabel>
          <Typography>{name}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Work name
          </FormLabel>
          <Typography>{workName}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Mobile
          </FormLabel>
          <Link href={"tel:" + mobile} variant="body2">
            {mobile}
          </Link>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Email
          </FormLabel>
          <Link href={"email:" + email} variant="body2">
            {email}
          </Link>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Nationality
          </FormLabel>
          <Typography>{nationality}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Age
          </FormLabel>
          <Typography>{age}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Height
          </FormLabel>
          <Typography>{height}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Hourly rate
          </FormLabel>
          <Typography>{hourlyRate}</Typography>
        </FormGrid>

        <FormGrid xs={12} md={6}>
          <FormLabel htmlFor="state">
            Created date
          </FormLabel>
          <Typography>{createdDate}</Typography>
        </FormGrid>

        <Button variant="contained" onClick={handleConvertToModel}>Convert to escort</Button>

      </Grid>

      <List >
        {images.map((item: ModelImage, index: number) => (

          <ListItem          >
            <ListItemAvatar>
              <Box
                component="img"
                sx={{
                  height: 200,
                }}
                src={configData.API_URL + item.path}
              />
            </ListItemAvatar>
          </ListItem>

        ))}
      </List>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessageOpen}
        onClose={handleErrorMessageClose}
        message={errorMessage}

      />

      <Snackbar open={successMessageOpen} autoHideDuration={6000} onClose={handleSuccessMessageClose}>
        <Alert
          onClose={handleSuccessMessageClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>


    </Stack>

  )
}
export default ProspectPage